import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCButton from '_components/inputs/LFCButton';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import ProgressBar from '_components/ProgressBar';
import {adminAPIAccess, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import awsConfiguration from 'awsConfiguration';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Dialog, DialogActions, DialogContent, Grid, Typography} from '@mui/material';

export type editMode = 'New' | 'Mod';
export type rowData = {
  mode: editMode;
  email: string;
  user_id: string;
  user_name: string;
  locale: string;
  effective_date: string;
};
export const emptyRowData: rowData = {
  mode: 'New',
  email: '',
  user_id: '',
  user_name: '',
  locale: 'ja',
  effective_date: '9999-12-31'
};

interface Props {
  open: boolean;
  onClose: (isOk: boolean) => void;
  row: rowData;
}

const UserUpsertDialog = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const formRef = useRef<HTMLFormElement>(null!);
  const [values, setValues] = useState(emptyRowData);

  const localeTypes: {name: string; value: string}[] = [
    {name: '日本語', value: 'ja'},
    {name: 'English', value: 'en'}
  ];

  /**
   * ユーザを作成する。
   * 終了後は、招待メールが飛ぶ。
   */
  const onCreateUser = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setStartProcess(true);

    adminAPIAccess({
      apipath: 'CreateUser',
      parameters: {
        user_pool_id: awsConfiguration.UserPoolId,
        user_id: values.user_id,
        user_name: values.user_name,
        email: values.email,
        is_dark: false,
        locale: values.locale,
        effective_date: values.effective_date
      }
    })
      .then(response => {
        switch (response.data.result_code) {
          case 0:
            enqueueSnackbar(
              t('message.登録されているアドレスにメールを送りました。ご確認下さい。'),
              {variant: 'success'}
            );
            props.onClose(true);
            break;
          case -1:
            console.log(
              `result_code:${response.data.result_code} / result_ex:${response.data.result_ex}`
            );
            switch (response.data.result_ex[0]) {
              case 'UsernameExistsException':
                enqueueSnackbar(
                  t('message.そのユーザーIDはすでに利用中です。他のIDで登録してください。'),
                  {
                    variant: 'error'
                  }
                );
                break;
              default:
                enqueueSnackbar(
                  `${t('message.エラーが発生しました。')} ${response.data.result_ex}`,
                  {
                    variant: 'error'
                  }
                );
                break;
            }
            break;
          case -2:
            //table登録エラー
            enqueueSnackbar(`${t('message.エラーが発生しました。')} ${response.data.result_ex}`, {
              variant: 'error'
            });
            break;
          case -3:
            enqueueSnackbar(
              `${t('message.作成可能なユーザ数の上限に達しているため、登録できません。上限=')}${
                response.data.result_ex[1]
              }`,
              {
                variant: 'error'
              }
            );
            break;
          default:
            throw new Error(
              `result_code:${response.data.result_code} / result_ex:${response.data.result_ex}`
            );
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(t(`aws.cognito.${error.message}`), {variant: 'error'});
        }
      })
      .finally(() => {
        setStartProcess(false);
      });
  };

  useEffect(() => {
    props.open && setValues(props.row);
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={(event: React.MouseEvent, reason: string) => {
        if (reason === 'backdropClick') {
          // 背景クリックでは閉じさせない
          return;
        }
        props.onClose(false);
      }}
      disableEscapeKeyDown
      maxWidth={'sm'}
    >
      <ProgressBar startProcess={startProcess} screenLock />
      <LFCDialogTitle onClose={() => props.onClose(false)}>
        {values.mode === 'New' ? t('新規') : t('変更')}
      </LFCDialogTitle>
      <DialogContent>
        <Grid container spacing={5} justifyContent={'center'}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{t('message.登録内容を入力してください')}</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <form ref={formRef}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <LFCTextField
                    name="user_id"
                    label={t('ユーザID')}
                    value={values.user_id}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    other={{
                      required: true,
                      inputProps: {minLength: 3, maxLength: 20, pattern: '^[a-z0-9_.\\-]*$'}
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCTextField
                    name="user_name"
                    label={t('ユーザー名')}
                    value={values.user_name}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    required
                    fullWidth
                    inputProps={{maxLength: 30}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCTextField
                    name="email"
                    label={t('メールアドレス')}
                    value={values.email}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    other={{
                      required: true,
                      inputProps: {
                        pattern: '^[a-z0-9_.+\\-]+@([a-z0-9][a-z0-9\\-]*\\.)+[a-z]{2,}$'
                      }
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <LFCSelectForm
                    name="locale"
                    label={t('表示言語')}
                    value={values.locale}
                    onChange={event => handleInputChange2(event, values, setValues)}
                    required
                    fullWidth
                    selectItem={localeTypes}
                    id={'locale'}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LFCButton onClick={() => props.onClose(false)}>{t('キャンセル')}</LFCButton>
        <LFCButton color="primary" onClick={onCreateUser}>
          {t('保存')}
        </LFCButton>
      </DialogActions>
    </Dialog>
  );
};
export default UserUpsertDialog;
