import LFCChartsBarSearchDay01 from '_components/charts/LFCChartsBarSearchDay01';
import LFCChartsBarSearchDay02 from '_components/charts/LFCChartsBarSearchDay02';
import LFCChartsBoxLRSearchByDay from '_components/charts/LFCChartsBoxLRSearchByDay';
import PieChartByMonthByMap from '_components/charts/pie/PieChartByMonthByMap';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValuePie from '_components/surfaces/LFCSingleValuePie';
import LFCSingleValuePiePar from '_components/surfaces/LFCSingleValuePiePar';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_LR_SEARCH_BY_DAY_MAIN, PAGEID_LR_SEARCH_BY_DAY} from '_logics/LFCPageId';
import {rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {distinct, getLFCData, handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios from 'axios';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import {Box, Dialog, DialogContent, Divider, Grid} from '@mui/material';
import {GridRenderCellParams} from '@mui/x-data-grid-pro';

import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
const SearchByDayPage = () => {
  const {t} = useTranslation();
  const location: any = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(true);
  const [data60332, setData60332] = useState([{total_count: '0', ng_count: '0'}]);
  const [data60340, setData60340] = useState([]);
  const [data60341, setData60341] = useState([]);
  const [data60342, setData60342] = useState([]);
  const [data6034300, setData6034300] = useState([]);
  const [data6034300_show, setData6034300_show] = useState([]);
  const [data60344, setData60344] = useState([]);
  const [data60345, setData60345] = useState([]);

  const [openResponse, setOpenResponse] = useState(false);
  const handleClose = () => setOpenResponse(false);

  let x_list: any = [];
  for (let i = 0; i < 24; i++) {
    x_list.push(String(('00' + i).slice(-2)));
  }

  let from_day: any = new Date();
  const initialSearchValue = {
    select_date:
      from_day.getFullYear() +
      '-' +
      ('0' + (from_day.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + from_day.getDate()).slice(-2),
    work: []
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LR_SEARCH_BY_DAY,
    LOCATIONID_LR_SEARCH_BY_DAY_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    apiFetch();
  };

  const apiFetch = async () => {
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60332,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      name: `${t('実績数と不良率')}`,
      cancelToken: source.token,
      t
    }).then((response: any) => {
      if (response.length === 0) {
        setData60332([{total_count: '0', ng_count: '0'}]);
      } else {
        setData60332(response);
      }
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60340,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60340,
      name: `LROBOT:${t('日報')}:${t('部位別不良率')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60341,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60341,
      name: `LROBOT:${t('日報')}:${t('検査数推移')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60342,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60342,
      name: `LROBOT:${t('日報')}:${t('平均精算間隔')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 6034300,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData6034300,
      name: `LROBOT:${t('日報')}:${t('生産間隔')} X ${t('頻度')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60344,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60344,
      name: `LROBOT:${t('日報')}:${t('生産間隔')}_${t('時間推移')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60345,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60345,
      name: `LROBOT:${t('日報')}:${t('データ明細リスト')}`,
      cancelToken: source.token,
      t
    });
  };

  useEffect(() => {
    if (location.state !== undefined) {
      setSearchValue({
        ...searchValue,
        select_date: location.state['select_date'].replaceAll(/\//g, '-')
      });
    }
  }, [location.state]);

  const [data60341_show, setData60341_show] = useState<any>([]);
  useEffect(() => {
    let tmp: any = [];
    let tmp_val: number = 0;
    if (data60341.length > 0) {
      data60341.forEach((item: any, index: number) => {
        if (index > 0) {
          tmp_val = tmp_val + item.検査数累計;
          tmp.push({
            時刻: item.時刻,
            検査数累計: tmp_val
          });
        } else {
          tmp_val = item.検査数累計;
          tmp.push({
            時刻: item.時刻,
            検査数累計: item.検査数累計
          });
        }
      });
    }
    setData60341_show(tmp);
  }, [data60341]);

  const [data60344_box, setData60344_box] = useState<any>({
    data_value: [],
    x_list: []
  });
  useEffect(() => {
    let tmp_datas: any = {
      x_list: x_list,
      data_value: []
    };
    tmp_datas.x_list.forEach((date_string: string, index: number) => {
      tmp_datas.data_value[index] = [];
      tmp_datas.data_value[index] = data60344
        .filter((item: any) => item.時刻 === date_string)
        .map((filter_item: any) => filter_item.cycle_time);
    });
    setData60344_box(tmp_datas);
    setStartProcess(false);
  }, [data60344]);

  useEffect(() => {
    let index_list: any = [];
    if (data6034300.length > 0) {
      let data_start: any = data6034300[0];
      let data_end: any = data6034300[data6034300.length - 1];
      for (let i: number = Number(data_start.index); data_end.index >= i; i++) {
        index_list.push(i);
      }
    }

    let tmp: any = [];
    index_list.forEach((index_number: number) => {
      if (data6034300.find((item: any) => String(item.index) === String(index_number))) {
        let tmp_data: any = data6034300.find(
          (item: any) => String(item.index) === String(index_number)
        );
        tmp.push({
          index: String(index_number),
          cycle_time: tmp_data['cycle_time'],
          カウント数: tmp_data['カウント数']
        });
      } else {
        tmp.push({
          index: String(index_number),
          cycle_time: String(index_number * 10) + '_' + String(index_number * 10 + 9),
          カウント数: 0
        });
      }
    });
    setData6034300_show(tmp);
  }, [data6034300]);

  let data60340_column = {
    ng_count: t('不良数'),
    lq_time: t('日付'),
    map: t('マップ')
  };
  let data60341_column = {
    時刻: t('時刻'),
    検査数累計: t('検査数累計')
  };
  let data6034300_column = {
    index: t('連番'),
    cycle_time: t('サイクルタイム'),
    カウント数: t('カウント')
  };
  let data60344_column = {
    時刻: t('集計時刻'),
    cycle_time: t('サイクルタイム'),
    lq_time: t('実時刻')
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: !isEmpty(searchValue.work) ? searchValue.work.join(',') : [],
        colName: t('機種'),
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate title={`L-ROBOT:${t('menu.page_name.日報')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatePicker
            name={'select_date'}
            label={t('日付')}
            value={searchValue.select_date}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
            required
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_LR_SEARCH_BY_DAY}
            locationNo={LOCATIONID_LR_SEARCH_BY_DAY_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} textAlign="right">
            <LFCButton color="secondary" onClick={() => setOpenResponse(true)}>
              {t('明細')}
            </LFCButton>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <LFCSingleValuePie title={t('生産数')} source={data60332} valueKey="total_count" />
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <LFCSingleValuePiePar
              title={t('良品')}
              source={data60332}
              valueKey="ok_count"
              totalValueKey="total_count"
              reverse
            />
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <LFCSingleValuePiePar
              title={t('不良品')}
              source={data60332}
              valueKey="ng_count"
              totalValueKey="total_count"
              reverse
            />
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <LFCSingleValuePie
              title={t('平均生産間隔')}
              source={data60342}
              valueKey="平均生産間隔"
              valueUnit="sec"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <PieChartByMonthByMap
              data={data60340}
              name={t('部位別不良率')}
              exportData={data60340}
              exportFields={data60340_column}
              exportFilename={t('部位別不良率')}
              colorSelectBaseData={distinct(data60340, 'map').sort()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsBarSearchDay01
              title={t('生産数推移')}
              source={data60341_show}
              exportData={data60341_show}
              exportFields={data60341_column}
              exportFilename={`${t('生産数推移')}`}
              yAxisName={`${t('生産数')}[${t('個')}]`}
              inputScale={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsBarSearchDay02
              title={`${t('生産数間隔')} X ${t('頻度')}`}
              source={data6034300_show}
              exportData={data6034300_show}
              exportFields={data6034300_column}
              exportFilename={`${t('生産数間隔頻度')}`}
              yAxisName={`${t('生産数')}(${t('個')})`}
              inputScale={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsBoxLRSearchByDay
              title={`${t('生産間隔')}_${t('時間推移')}`}
              source={data60344_box}
              exportData={data60344}
              exportFields={data60344_column}
              exportFilename={`${t('生産間隔')}_${t('時間推移')}`}
              height={'35vh'}
              top={'60'}
              bottom={'30'}
              yAxisName={`${t('生産間隔')}(${t('秒')})`}
              inputScale={true}
              xlist={x_list}
              xAxisName={`${t('時刻帯(時)')}`}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openResponse} onClose={handleClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={handleClose}>{`${t('日報')}・${t('明細')}`}</LFCDialogTitle>
        <DialogContent>
          <LFCDataGridSimple
            columns={[
              {
                field: '日時',
                headerName: `${t('日時')}`,
                description: `${t('日時')}`,
                sortable: false,
                width: 300,
                align: 'center'
              },
              {
                field: 'シリアル',
                headerName: `${t('シリアル')}`,
                description: `${t('シリアル')}`,
                width: 300,
                sortable: false
              },
              {
                field: '機種',
                headerName: `${t('機種')}`,
                description: `${t('機種')}`,
                width: 200,
                sortable: false
              },
              {
                field: '判定',
                headerName: `${t('判定')}`,
                description: `${t('判定')}`,
                renderCell: (prms: GridRenderCellParams) => rendTotalJudgeDisp(prms.value),
                width: 180,
                sortable: false
              },
              {
                field: '検査間隔',
                headerName: `${t('検査間隔')}`,
                description: `${t('検査間隔')}`,
                width: 180,
                sortable: false
              }
            ]}
            rows={data60345}
            height="80vh"
            exportFilename={`${t('日報')}_${t('明細')}`}
          />
        </DialogContent>
      </Dialog>
    </GenericTemplate>
  );
};

export default SearchByDayPage;
