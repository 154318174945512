import LFCChartsBarSearchMonth from '_components/charts/LFCChartsBarSearchMonth';
import LFCChartsBarSearchMonthStack from '_components/charts/LFCChartsBarSearchMonthStack';
import LFCChartsBarSearchMonthStackJobtype from '_components/charts/LFCChartsBarSearchMonthStackJobtype';
import LFCChartsBoxSearchByMonth from '_components/charts/LFCChartsBoxSearchByMonth';
import LFCChartsComboMMDD from '_components/charts/LFCChartsComboMMDD';
import PieChartByMonthByJobtype from '_components/charts/pie/PieChartByMonthByJobtype';
import PieChartByMonthByMap from '_components/charts/pie/PieChartByMonthByMap';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import DateTimePicker from '_components/inputs/DateTimePicker';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCTitleFrame from '_components/surfaces/LFCTitleFrame';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {LOCATIONID_LQ_SEARCH_BY_MONTH_MAIN, PAGEID_LQ_SEARCH_BY_MONTH} from '_logics/LFCPageId';
import {distinct, getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios, {CancelToken, CancelTokenSource} from 'axios';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {green, red} from '@mui/material/colors';

import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
type rowData60333 = {
  '不良品/日数': string;
  不良品数: string;
  不良率: string;
  平均生産間隔: string;
  数量総合計: string;
  日数: string;
  '総数/日数': string;
  '良品/日数': string;
  良品数: string;
};
const emptyRowData60333: rowData60333 = {
  '不良品/日数': '-',
  不良品数: '-',
  不良率: '-',
  平均生産間隔: '-',
  数量総合計: '-',
  日数: '-',
  '総数/日数': '-',
  '良品/日数': '-',
  良品数: '-'
};

const SearchByMonthPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const cancelSource = useRef<CancelTokenSource | null>(null);
  const [days_list, setDays_list] = useState<string[]>([]);

  const initialSearchValue = {
    select_year_month: dayjs().format('YYYY-MM'),
    select_date_from: dayjs().format('YYYY/MM/01'),
    select_date_to: dayjs().format('YYYY/MM/DD'),
    select_date_from_1: '',
    select_date_to_1: '',
    select_date_from_2: '',
    select_date_to_2: '',
    select_date_from_3: '',
    select_date_to_3: '',
    work: [],
    cycle_time_from: 0,
    cycle_time_to: 200
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LQ_SEARCH_BY_MONTH,
    LOCATIONID_LQ_SEARCH_BY_MONTH_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const [data60333, setData60333] = useState<rowData60333>(emptyRowData60333);

  const [data60335, setData60335] = useState([]);
  const [data60336, setData60336] = useState([]);
  const [data61337, setData61337] = useState([]);
  const [data61337_show, setData61337_show] = useState([]);
  const [data60338, setData60338] = useState([]);
  const [data60339, setData60339] = useState([]);
  const [data603381, setData603381] = useState([]);
  const [data603391, setData603391] = useState([]);
  const [data60346, setData60346] = useState([]);

  const [openResponse, setOpenResponse] = useState(false);
  const handleClose = () => setOpenResponse(false);

  const makeDaysList = () => {
    const startDate = dayjs(searchValue.select_date_from);
    const daysInMonth = startDate.daysInMonth();

    const tmp_days_list = Array.from({length: daysInMonth}, (_, i) => {
      return startDate.date(i + 1).format('MM/DD');
    });
    setDays_list(tmp_days_list);
  };

  useEffect(() => {
    const endDate = dayjs(searchValue.select_year_month).endOf('month');
    const startDate = endDate.startOf('month');

    setSearchValue({
      ...searchValue,
      select_date_from: startDate.format('YYYY/MM/01'),
      select_date_to: endDate.format('YYYY/MM/DD')
    });
  }, [searchValue.select_year_month]);

  const doSearch = async () => {
    if (cancelSource.current) cancelSource.current.cancel('req cancel');

    if (!formRef.current?.reportValidity()) {
      return;
    }

    setStartProcess(true);

    setData60335([]);
    setData60336([]);
    setData60338([]);
    setData60339([]);
    setData60333(emptyRowData60333);
    makeDaysList();
    setData61337([]);
    setData603381([]);
    setData603391([]);

    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61335,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        ds_state: setData60335,
        name: `${t('生産数と不良率')}`,
        cancelToken: cancelSource.current.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61336,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          cycle_time_from: searchValue.cycle_time_from,
          cycle_time_to: searchValue.cycle_time_to
        },
        ds_state: setData60336,
        name: `${t('生産間隔')} X ${t('時間推移')}`,
        cancelToken: cancelSource.current.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61338,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        name: `${t('部位別')}　${t('不良数推移')}`,
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => {
        setData60338(datas);
        setData603381(datas);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61339,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        name: `${t('生産手法別')}　${t('不良数推移')}`,
        cancelToken: cancelSource.current.token,
        t
      }).then(datas => {
        setData60339(datas);
        setData603391(datas);
      }),
      getSumcols(cancelSource.current.token),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61337,
        parameters: {
          select_date_from:
            searchValue.select_date_from_1 !== ''
              ? searchValue.select_date_from_1
              : searchValue.select_date_from,
          select_date_to:
            searchValue.select_date_to_1 !== ''
              ? searchValue.select_date_to_1
              : searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          cycle_time_from: searchValue.cycle_time_from,
          cycle_time_to: searchValue.cycle_time_to
        },
        ds_state: setData61337,
        name: `${t('生産間隔')} X ${t('頻度')}`,
        cancelToken: cancelSource.current.token,
        t
      })
    ]).then(res => {
      if (!res.find((r: any) => String(r['reason']).match(/Error: Cancel:/))) {
        setStartProcess(false);
      }
    });
  };

  const getSumcols = async (token: CancelToken) => {
    await Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61334,
        parameters: {
          select_date: searchValue.select_date_from,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        cancelToken: token,
        t
      }).then(datas => datas[0])
    ]).then(res => {
      if (res.find((r: any) => String(r['reason']).match(/Error:/))) {
        //エラー（キャンセル含む場合は何もしない）
        return;
      }

      const resSum = res.reduce((acc: Record<string, any>, curr: Record<string, any>) => {
        return {...acc, ...curr['value']};
      }, {});

      const suryoSouGokei = resSum['数量総合計'] || 0;
      const nissu = resSum['日数'] || 0;
      const furyoHinSu = resSum['不良品数'] || 0;
      const hekinSeisanKankaku = resSum['平均間隔'] || 0;

      setData60333({
        '不良品/日数': (furyoHinSu / nissu).toFixed(1),
        不良品数: String(furyoHinSu),
        不良率: ((furyoHinSu / suryoSouGokei) * 100).toFixed(1),
        平均生産間隔: String(hekinSeisanKankaku),
        数量総合計: String(suryoSouGokei),
        日数: String(nissu),
        '総数/日数': String((suryoSouGokei / nissu).toFixed(1)),
        '良品/日数': ((suryoSouGokei - furyoHinSu) / nissu).toFixed(1),
        良品数: String(suryoSouGokei - furyoHinSu)
      });
    });
  };

  const handleChangeCycleTime = (event: any) => {
    if (!Number(event.target.value)) {
      setSearchValue({...searchValue, [event.target.name]: 0});
      return;
    }
    setSearchValue({...searchValue, [event.target.name]: Number(event.target.value)});
  };

  const [data60336_box, setData60336_box] = useState<any>({
    data_value: [],
    x_list: []
  });

  useEffect(() => {
    let tmp_datas: any = {
      x_list: distinct(data60336, 'lq_time'),
      data_value: []
    };
    tmp_datas.x_list.forEach((date_string: string, index: number) => {
      tmp_datas.data_value[index] = [];
      tmp_datas.data_value[index] = data60336
        .filter((item: any) => item.lq_time === date_string)
        .map((filter_item: any) => filter_item.cycle_time);
    });
    setData60336_box(tmp_datas);
    // setStartProcess(false);
  }, [data60336]);

  const graphClick_gotoDay = (data: any) => {
    if (data.componentType === 'series') {
      history.push({pathname: '/l-robot/search-by-day', state: {select_date: data.name}});
    }
  };

  const graphClick_01_selectDate = (selectData: any) => {
    const buildDateParameters = (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => ({
      select_date_from: fromDate.format('YYYY/MM/DD'),
      select_date_to: toDate.format('YYYY/MM/DD'),
      work: !isEmpty(searchValue.work) ? `{${searchValue.work.join(',')}}` : null,
      cycle_time_from: searchValue.cycle_time_from,
      cycle_time_to: searchValue.cycle_time_to
    });

    let fromDate = dayjs(searchValue.select_date_from);
    let toDate = dayjs(searchValue.select_date_to);

    if (selectData !== '') {
      fromDate = dayjs(`${fromDate.year()}/${selectData.name}`);
      toDate = fromDate;

      setSearchValue({
        ...searchValue,
        select_date_from_1: fromDate.format('YYYY/MM/DD')
      });
    } else {
      setSearchValue({...searchValue, select_date_from_1: ''});
    }

    cancelSource.current = axios.CancelToken.source();
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61337,
        parameters: buildDateParameters(fromDate, toDate),
        ds_state: setData61337,
        cancelToken: cancelSource.current.token,
        t
      })
    ]).then(res => {
      if (!res.find((r: any) => String(r['reason']).match(/Error: Cancel:/))) {
        setStartProcess(false);
      }
    });
  };

  const graphClick_02_selectDate = (selectData: any) => {
    const buildDateParameters = (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => ({
      select_date_from: fromDate.format('YYYY/MM/DD'),
      select_date_to: toDate.format('YYYY/MM/DD'),
      work: !isEmpty(searchValue.work) ? `{${searchValue.work.join(',')}}` : null
    });

    let fromDate = dayjs(searchValue.select_date_from);
    let toDate = dayjs(searchValue.select_date_to);

    if (selectData !== '') {
      fromDate = dayjs(`${fromDate.year()}/${selectData.name}`);
      toDate = fromDate;

      setSearchValue({
        ...searchValue,
        select_date_from_2: fromDate.format('YYYY/MM/DD')
      });
    } else {
      setSearchValue({...searchValue, select_date_from_2: ''});
    }

    cancelSource.current = axios.CancelToken.source();
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61338,
        parameters: buildDateParameters(fromDate, toDate),
        ds_state: setData603381,
        cancelToken: cancelSource.current.token,
        t
      })
    ]).then(res => {
      if (!res.find((r: any) => String(r['reason']).match(/Error: Cancel:/))) {
        setStartProcess(false);
      }
    });
  };

  const graphClick_03_selectDate = (selectData: any) => {
    const buildDateParameters = (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => ({
      select_date_from: fromDate.format('YYYY/MM/DD'),
      select_date_to: toDate.format('YYYY/MM/DD'),
      work: !isEmpty(searchValue.work) ? `{${searchValue.work.join(',')}}` : null
    });

    let fromDate = dayjs(searchValue.select_date_from);
    let toDate = dayjs(searchValue.select_date_to);

    if (selectData !== '') {
      fromDate = dayjs(`${fromDate.year()}/${selectData.name}`);
      toDate = fromDate;

      setSearchValue({
        ...searchValue,
        select_date_from_3: fromDate.format('YYYY/MM/DD')
      });
    } else {
      setSearchValue({...searchValue, select_date_from_3: ''});
    }

    cancelSource.current = axios.CancelToken.source();
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 61339,
        parameters: buildDateParameters(fromDate, toDate),
        ds_state: setData603391,
        cancelToken: cancelSource.current.token,
        t
      })
    ]).then(res => {
      if (!res.find((r: any) => String(r['reason']).match(/Error: Cancel:/))) {
        setStartProcess(false);
      }
    });
  };

  useEffect(() => {
    let index_list: any = [];
    if (data61337.length > 0) {
      let data_start: any = data61337[0];
      let data_end: any = data61337[data61337.length - 1];
      for (let i: number = Number(data_start.index); data_end.index >= i; i++) {
        index_list.push(i);
      }
    }

    let tmp: any = [];
    index_list.forEach((index_number: number) => {
      const find_data: any = data61337.find((item: any) => item.index === index_number);

      if (find_data) {
        tmp.push({
          index: String(index_number),
          cycle_time: find_data['cycle_time'],
          カウント数: find_data['カウント数']
        });
      } else {
        tmp.push({
          index: String(index_number),
          cycle_time: String(index_number * 10) + '_' + String(index_number * 10 + 9),
          カウント数: 0
        });
      }
    });
    setData61337_show(tmp);
  }, [data61337]);

  const toLocalStringEx = (val: string) => {
    return isNaN(Number(val)) ? val : Number(val).toLocaleString();
  };

  let responseData004_column = {
    lq_time: t('日時'),
    total_count: t('生産数'),
    ng_par: t('不良率'),
    ng: t('不良数')
  };
  let data60336_column = {
    lq_time: t('日時'),
    cycle_time: t('生産間隔')
  };
  let data61337_column = {
    index: t('連番'),
    cycle_time: t('生産間隔'),
    カウント数: t('カウント')
  };
  let data60338_column = {
    lq_time: t('日時'),
    map: t('マップ'),
    ng_count: t('不良数')
  };
  let data60339_column = {
    lq_time: t('日時'),
    job_type_name: t('ジョブタイプ名称'),
    ng_count: t('不良数')
  };

  const monthSelectChange = (event: any) => {
    setSearchValue({
      ...searchValue,
      select_year_month: event.format('YYYY-MM'),
      select_date_from_1: '',
      select_date_to_1: '',
      select_date_from_2: '',
      select_date_to_2: '',
      select_date_from_3: '',
      select_date_to_3: ''
    });
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'cycle_time_from',
        value: searchValue.cycle_time_from,
        valueLabel: searchValue.cycle_time_from,
        colName: t('生産間隔From'),
        colWidth: 150
      },
      {
        name: 'cycle_time_to',
        value: searchValue.cycle_time_to,
        valueLabel: searchValue.cycle_time_to,
        colName: t('生産間隔To'),
        colWidth: 150
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);

  useEffect(() => {
    cancelSource.current = axios.CancelToken.source();
    Promise.allSettled([
      //機種プルダウン
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 40001,
        parameters: {},
        cancelToken: cancelSource.current.token,
        t
      })
        .then(ds => {
          const tmp: {label: string}[] = ds.map((item: any) => {
            return {label: item['work']};
          });
          setWork(tmp);
        })
        .catch(e => e)
    ]).then(() => {
      setStartProcess(false);
    });

    return () => {
      if (cancelSource.current) cancelSource.current.cancel('req cancel');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  useEffect(() => {
    if (openResponse) {
      setData60346([]);

      cancelSource.current = axios.CancelToken.source();
      Promise.allSettled([
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 61346,
          parameters: {
            select_date: dayjs(searchValue.select_date_from).format('YYYY/MM'),
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60346,
          name: `LROBOT:${t('月間集計')}:${t('データ明細リスト')}`,
          cancelToken: cancelSource.current.token,
          t
        })
      ]).then(res => {
        if (!res.find((r: any) => String(r['reason']).match(/Error: Cancel:/))) {
          setStartProcess(false);
        }
      });
    }
  }, [openResponse]);

  return (
    <GenericTemplate title={`${t('menu.page_name.月間集計')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <DateTimePicker
            label={t('年月')}
            value={dayjs(searchValue.select_year_month)}
            format="YYYY/MM"
            views={['year', 'month']}
            onChange={event => {
              monthSelectChange(event);
            }}
            sx={{width: '150px'}}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small"
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCTitleFrame title={t('生産間隔')}>
            <TextField
              variant="standard"
              required
              name={'cycle_time_from'}
              value={searchValue.cycle_time_from}
              onChange={handleChangeCycleTime}
              size="small"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                type: 'number',
                min: 0,
                max: 9999
              }}
              sx={{width: '75px', margin: '9px 0px 2px'}}
            />
            <Typography variant="body1" sx={{width: '30px', marginTop: '7px'}}>
              ～
            </Typography>
            <TextField
              variant="standard"
              required
              name={'cycle_time_to'}
              value={searchValue.cycle_time_to}
              onChange={handleChangeCycleTime}
              size="small"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                type: 'number',
                min: 0,
                max: 9999
              }}
              sx={{width: '75px', margin: '9px 0px 2px'}}
            />
          </LFCTitleFrame>
          <LFCButton color="primary" onClick={() => doSearch()} disabled={startProcess}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_LQ_SEARCH_BY_MONTH}
            locationNo={LOCATIONID_LQ_SEARCH_BY_MONTH_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <LFCChartsComboMMDD
              title={t('生産数と不良率')}
              source={data60335}
              xlist={days_list}
              yLeft={{
                type: 'bar',
                name: `${t('生産数')}(${t('個')})`,
                dsColumn: 'total_count'
              }}
              yRight={{
                type: 'line',
                name: `${t('不良率')}(%)`,
                dsColumn: 'ng_par',
                markPoint: {
                  data: [
                    {type: 'min', itemStyle: {color: '#E91E63'}},
                    {type: 'max', itemStyle: {color: '#2196F3'}}
                  ]
                },
                markLine: {
                  symbol: 'arrow',
                  data: [
                    {
                      type: 'average',
                      label: {
                        position: 'end',
                        formatter: 'Ave. {c}',
                        color: '#FF9800'
                      },
                      lineStyle: {
                        color: '#FF9800'
                      }
                    }
                  ]
                }
              }}
              color={[green[500], red[500]]}
              // height="40vh"
              exportData={data60335}
              exportFields={responseData004_column}
              exportFilename={`${t('実績数と不良率')}`}
              onDoubleClick={graphClick_gotoDay}
              inputScale={true}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <LFCSingleValues
              columns={[
                {
                  field: '数量総合計',
                  headerName: t('総合計'),
                  width: '46%',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                },
                {
                  field: '総数/日数',
                  headerName: `${t('総合計')} ${t('平均')}/${t('日')}`,
                  width: '46%',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
            <LFCSingleValues
              columns={[
                {
                  field: '良品数',
                  headerName: t('良品'),
                  width: '46%',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                },
                {
                  field: '良品/日数',
                  headerName: `${t('良品')} ${t('平均')}/${t('日')}`,
                  width: '46%',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
            <LFCSingleValues
              columns={[
                {
                  field: '不良品数',
                  headerName: t('不良品'),
                  width: '46%',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                },
                {
                  field: '不良品/日数',
                  headerName: `${t('不良品')} ${t('平均')}/${t('日')}`,
                  width: '46%',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
            <LFCSingleValues
              columns={[
                {
                  field: '不良率',
                  headerName: `${t('不良率')}`,
                  width: '46%',
                  unit: '%',
                  formatter: toLocalStringEx
                },
                {
                  field: '平均生産間隔',
                  headerName: t('平均生産間隔'),
                  width: '46%',
                  unit: `${t('秒')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
          </Grid>
          <Grid item xs={12} lg={1} textAlign="right">
            <LFCButton
              color="secondary"
              onClick={() => setOpenResponse(true)}
              disabled={startProcess}
            >
              {t('明細')}
            </LFCButton>
          </Grid>
          <Grid item xs={12} lg={8}>
            <LFCChartsBoxSearchByMonth
              title={`${t('生産間隔')} X ${t('時間推移')}`}
              source={data60336_box}
              xlist={days_list}
              exportData={data60336}
              exportFields={data60336_column}
              exportFilename={`${t('生産間隔')} X ${t('時間推移')}`}
              yAxisName={`${t('生産間隔')}(${t('秒')})`}
              onClick={(data: any) => graphClick_01_selectDate(data)}
              inputScale={true}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <Stack direction="row">
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <DoubleArrowIcon></DoubleArrowIcon>
                <Chip
                  label={
                    searchValue.select_date_from_1 === ''
                      ? t('すべて')
                      : dayjs(searchValue.select_date_from_1).format('MM/DD')
                  }
                  onDelete={
                    searchValue.select_date_from_1 !== ''
                      ? () => graphClick_01_selectDate('')
                      : undefined
                  }
                />
              </Stack>
              <Box width={'85%'}>
                <LFCChartsBarSearchMonth
                  title={`${t('生産間隔')} X ${t('頻度')}`}
                  source={data61337_show}
                  exportData={data61337_show}
                  exportFields={data61337_column}
                  exportFilename={`${t('生産間隔')} X ${t('頻度')}`}
                  xAxisName={`${t('生産数')}(${t('個')})`}
                  inputScale={true}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={8}>
            <LFCChartsBarSearchMonthStack
              title={`${t('部位別')}　${t('不良数推移')}`}
              source={data60338}
              xlist={days_list}
              exportData={data60338}
              exportFields={data60338_column}
              exportFilename={`${t('部位別')}_${t('不良数推移')}`}
              onClick={graphClick_02_selectDate}
              colorSelectBaseData={distinct(data60338, 'map').sort()}
              inputScale={true}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack direction="row">
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <DoubleArrowIcon></DoubleArrowIcon>
                <Chip
                  label={
                    searchValue.select_date_from_2 === ''
                      ? t('すべて')
                      : dayjs(searchValue.select_date_from_2).format('MM/DD')
                  }
                  onDelete={
                    searchValue.select_date_from_2 !== ''
                      ? () => graphClick_02_selectDate('')
                      : undefined
                  }
                />
              </Stack>
              <Box width={'85%'}>
                <PieChartByMonthByMap
                  data={data603381}
                  name={t('部位別不良率')}
                  exportData={data603381}
                  exportFields={data60338_column}
                  exportFilename={t('部位別不良率')}
                  colorSelectBaseData={distinct(data60338, 'map').sort()}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={8}>
            <LFCChartsBarSearchMonthStackJobtype
              title={`${t('生産手法別')}　${t('不良数推移')}`}
              source={data60339}
              xlist={days_list}
              exportData={data60339}
              exportFields={data60339_column}
              exportFilename={`${t('生産手法別')}_${t('不良数推移')}`}
              onClick={graphClick_03_selectDate}
              colorSelectBaseData={distinct(data60339, 'job_type_name').sort()}
              inputScale={true}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack direction="row">
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <DoubleArrowIcon></DoubleArrowIcon>
                <Chip
                  label={
                    searchValue.select_date_from_3 === ''
                      ? t('すべて')
                      : dayjs(searchValue.select_date_from_3).format('MM/DD')
                  }
                  onDelete={
                    searchValue.select_date_from_3 !== ''
                      ? () => graphClick_03_selectDate('')
                      : undefined
                  }
                />
              </Stack>
              <Box width={'85%'}>
                <PieChartByMonthByJobtype
                  data={data603391}
                  name={t('生産手法別不良率')}
                  exportData={data603391}
                  exportFields={data60339_column}
                  exportFilename={t('生産手法別不良率')}
                  colorSelectBaseData={distinct(data60339, 'job_type_name').sort()}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openResponse} onClose={handleClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={handleClose}>{t('日別明細')}</LFCDialogTitle>
        <DialogContent>
          <LFCDataGridSimple
            columns={[
              {
                field: '日付',
                headerName: `${t('日付')}`,
                description: `${t('日付')}`,
                sortable: false,
                width: 200,
                align: 'center'
              },
              {
                field: '検査数',
                headerName: `${t('生産数')}`,
                description: `${t('生産数')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '良品数',
                headerName: `${t('良品数')}`,
                description: `${t('良品数')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '不良数',
                headerName: `${t('不良数')}`,
                description: `${t('不良数')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '不良率',
                headerName: `${t('不良率')}`,
                description: `${t('不良率')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '平均ジョブ処理時間',
                headerName: `${t('平均ジョブ処理時間')}`,
                description: `${t('平均ジョブ処理時間')}`,
                width: 150,
                sortable: false,
                align: 'right'
              }
            ]}
            rows={data60346}
            height="80vh"
            exportFilename={`${t('日別明細')}`}
          />
        </DialogContent>
      </Dialog>
    </GenericTemplate>
  );
};

export default SearchByMonthPage;
