import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {distinct} from '_logics/LFCUtil';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  data: any;
  onEvents?: any;
  name: string;
  exportData: any;
  exportFields: any;
  exportFilename: string;
  colorSelectBaseData: any;
}

const PieChartByMonthByMap = (props: Props) => {
  const {t} = useTranslation();
  let x_list: any = props.data !== undefined ? distinct(props.data, 'lq_time') : false;
  let map_list: any = props.data !== undefined ? distinct(props.data, 'map').sort() : false;

  let color: any = colorPallet;

  let map_grouping_data: any = [];
  map_list.forEach((map_name: string, index: number) => {
    let tmp: any = props.data.filter((item: any) => map_name === item.map);
    let tmp_data: any = [];
    x_list.forEach((date_string: string) => {
      tmp_data.push(
        tmp.filter((item: any) => item.lq_time === date_string).length > 0
          ? Number(tmp.filter((item: any) => item.lq_time === date_string)[0].ng_count)
          : 0
      );
    });
    map_grouping_data.push({
      name: map_name,
      value: tmp_data.reduce((sum: number, item: any) => sum + item, 0),
      itemStyle: {
        color:
          color[
            props.colorSelectBaseData.findIndex((map_item: string) => map_item === map_name)
          ] !== undefined
            ? color[
                props.colorSelectBaseData.findIndex((map_item: string) => map_item === map_name)
              ]
            : '#000080'
      }
    });

    map_grouping_data = map_grouping_data.sort((a: any, b: any) => {
      return a.value > b.value ? -1 : 1;
    });
  });

  const option = {
    title: {
      text: props.name
    },
    tooltip: {
      trigger: 'item',
      formatter: (prm: any) => {
        return (
          prm.name +
          '<br>' +
          prm.value +
          t('件') +
          (prm.percent !== undefined ? ' (' + prm.percent.toFixed(1) + '%)' : '')
        );
      },
      position: 'inside'
    },
    legend: {
      show: false
    },
    series: [
      {
        name: props.data.name,
        type: 'pie',
        radius: ['20%', '70%'],
        avoidLabelOverlap: false,
        label: {
          formatter: (prm: any) => {
            return (
              prm.name +
              '\n' +
              prm.value +
              t('件') +
              '\n' +
              (prm.percent !== undefined ? prm.percent.toFixed(1) + '%' : '')
            );
          },
          position: 'inside'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: map_grouping_data
      }
    ]
  };

  return (
    <LFCBaseCharts
      option={option}
      onEvents={props.onEvents}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
    />
  );
};

export default PieChartByMonthByMap;
