import {Box, Typography} from '@mui/material';
import G261_Lower from '_assets/images/development/g261/G261_Lower.png';
import G261_Middle from '_assets/images/development/g261/G261_Middle.png';
import G261_Upper from '_assets/images/development/g261/G261_Upper.png';
import G231_Lower from '_assets/images/development/g231/G231_Lower.png';
import G231_Middle from '_assets/images/development/g231/G231_Middle.png';
import G231_Upper from '_assets/images/development/g231/G231_Upper.png';
import BX_US_Lower from '_assets/images/development/bx_us/BX_US_Lower.png';
import BX_US_Middle from '_assets/images/development/bx_us/BX_US_Middle.png';
import BX_US_Upper from '_assets/images/development/bx_us/BX_US_Upper.png';
import BX_EU_Lower from '_assets/images/development/bx_eu/BX_EU_Lower.png';
import BX_EU_Middle from '_assets/images/development/bx_eu/BX_EU_Middle.png';
import BX_EU_Upper from '_assets/images/development/bx_eu/BX_EU_Upper.png';
import {DisplayImage} from './Boxes';

export interface DisplayData {
  deviceid: string;
  judge: number;
  lq_time: string;
  rn: number;
  serial: string;
  work: string;
  map: string;
  uuid: string;
}

interface Props {
  displayData: DisplayData[];
  setOpen: (uuid: string, map: string) => Promise<void>;
  isDisplayNgOnly: boolean;
  openDataScreenShot: string;
  imageFullPath: string;
  work: string;
  isDisplayName: boolean;
}

export const ResultsDisplay = ({
  displayData,
  setOpen,
  isDisplayNgOnly,
  openDataScreenShot,
  imageFullPath,
  isDisplayName
}: Props) => {
  const data = displayData?.filter(e => !isDisplayNgOnly || e.judge === 1);
  const work = data?.[0]?.work;
  const images =
    work === 'G261'
      ? [G261_Upper, G261_Middle, G261_Lower]
      : work === 'G231'
        ? [G231_Upper, G231_Middle, G231_Lower]
        : work === 'BX_US'
          ? [BX_US_Upper, BX_US_Middle, BX_US_Lower]
          : work === 'BX_EU'
            ? [BX_EU_Upper, BX_EU_Middle, BX_EU_Lower]
            : [];
  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" gap="25px" width="70%">
        {images.map((image, index) => {
          return (
            <Box
              height="auto"
              justifyContent="center"
              display="flex"
              position={'relative'}
              key={image}
            >
              <img src={image} width="90%" alt="" />;
              <DisplayImage
                data={data}
                setOpen={setOpen}
                openDataScreenShot={openDataScreenShot}
                index={index}
                isDisplayName={isDisplayName}
              />
            </Box>
          );
        })}
      </Box>
      {openDataScreenShot && (
        <Box width="30%" marginRight={5}>
          <Box display="flex" marginBottom={5} gap={5}>
            <Box
              height="70px"
              width="200px"
              bgcolor={
                data?.find((e: DisplayData) => e.map === openDataScreenShot)?.judge !== 1
                  ? '#04b454'
                  : '#fc0404'
              }
              color="black"
              fontSize="40px"
              alignItems="center"
              justifyContent={'center'}
              display="flex"
            >
              {data?.find((e: DisplayData) => e.map === openDataScreenShot)?.judge !== 1
                ? 'OK'
                : 'NG'}
            </Box>
            <Box
              height="70px"
              width="500px"
              bgcolor="white"
              color="black"
              fontSize="40px"
              alignItems="center"
              justifyContent={'center'}
              display="flex"
              border={1}
            >
              <Typography>{openDataScreenShot}</Typography>
            </Box>
          </Box>
          <Box marginY="auto" height="400px">
            <Box
              style={{
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%'
              }}
            >
              <img
                src={imageFullPath}
                alt=""
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
