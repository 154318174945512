/* 画像の配置はabsoluteでやるしかないのでここに記載するs */
export const calcPosition = (work: string, map: string, index: number): [string, string] => {
  if (work === 'G261') {
    if (index === 0) {
      switch (map) {
        case '1105_5-1':
          return ['79', '35'];
        case '1105_6-1':
          return ['74', '35'];
        case '1105_10-1':
          return ['66', '22'];
        case '1105_2-1':
          return ['62', '22'];
        case '1105_2-2':
          return ['58', '22'];
        case '1105_2-3':
          return ['55', '22'];
        case '1105_2-4':
          return ['52', '22'];
        case '1105_7-1':
          return ['79', '60'];
        case '1105_8-1':
          return ['75', '60'];
        case '1108_12-1':
          return ['86', '72'];
        case '1108_10-1':
          return ['79', '73'];
        case '1105_4-1':
          return ['66', '47'];
        case '1105_4-2':
          return ['66', '58'];
        case '1105_9-1':
          return ['65', '68'];
        case '1105_1-1':
          return ['62', '65'];
        case '1105_1-2':
          return ['60', '65'];
        case '1105_1-3':
          return ['58', '65'];
        case '1105_1-4':
          return ['55', '60'];
        case '1105_1-5':
          return ['50', '60'];
        case '1108_5-1':
          return ['40', '70'];
        case '1108_3-1':
          return ['36', '70'];
        case '1108_5-2':
          return ['40', '80'];
        default:
          return ['0', '0'];
      }
    } else if (index === 1) {
      switch (map) {
        case '1108_11-1':
          return ['85', '20'];
        case '1108_8-4':
          return ['72', '25'];
        case '1108_8-3':
          return ['68', '25'];
        case '1108_9-1':
          return ['70', '30'];
        case '1108_8-2':
          return ['65', '25'];
        case '1108_8-1':
          return ['62', '25'];
        case '1108_7-1':
          return ['58', '28'];
        case '1108_6-1':
          return ['55', '28'];
        case '1108_4-1':
          return ['39', '25'];
        case '1108_2-1':
          return ['33', '24'];
        case '1108_1-1':
          return ['30', '24'];
        case '1109_1-2':
          return ['62', '68'];
        case '1109_1-1':
          return ['57', '68'];
        default:
          return ['0', '0'];
      }
    } else if (index === 2) {
      switch (map) {
        case '1109_3-1':
          return ['79', '13'];
        case '1109_5-1':
          return ['75', '13'];
        case '1109_2-2':
          return ['62', '30'];
        case '1109_2-1':
          return ['58', '30'];
        case '1109_4-1':
          return ['79', '25'];
        case '1107_1-1':
          return ['68', '30'];
        case '1109_6-1':
          return ['75', '25'];
        case '1106_1-2':
          return ['73', '62'];
        case '1106_1-1':
          return ['73', '75'];
        case '1106_2-1':
          return ['70', '62'];
        case '1106_3-2':
          return ['92', '60'];
        case '1106_3-1':
          return ['92', '72'];
        case '1105_3-3':
          return ['55', '75'];
        case '1106_2-2':
          return ['70', '75'];
        case '1105_3-2':
          return ['50', '70'];
        case '1105_11-1':
          return ['39', '42'];
        case '1106_4-1':
          return ['40', '72'];
        case '1105_3-1':
          return ['45', '75'];
        default:
          return ['0', '0'];
      }
    }
  } else if (work === 'G231') {
    if (index === 0) {
      switch (map) {
        case '1112_12-1':
          return ['86', '75'];
        case '1112_10-1':
          return ['78', '72'];
        case '1110_7-1':
          return ['76', '60'];
        case '1110_5-1':
          return ['76', '35'];
        case '1110_6-1':
          return ['70', '35'];
        case '1110_8-1':
          return ['52', '22'];
        case '1105_10-1':
          return ['68', '22'];
        case '1110_2-1':
          return ['62', '22'];
        case '1110_2-2':
          return ['58', '22'];
        case '1110_2-3':
          return ['55', '22'];
        case '1110_2-4':
          return ['53', '22'];
        case '1110_4-1':
          return ['66', '47'];
        case '1110_4-2':
          return ['66', '58'];
        case '1110_9-1':
          return ['65', '68'];
        case '1110_1-5':
          return ['62', '65'];
        case '1110_1-4':
          return ['60', '65'];
        case '1110_1-3':
          return ['58', '65'];
        case '1110_1-2':
          return ['55', '60'];
        case '1110_1-1':
          return ['50', '60'];
        case '1112_5-1':
          return ['40', '70'];
        case '1112_3-1':
          return ['36', '70'];
        case '1112_5-2':
          return ['40', '80'];
        default:
          return ['0', '0'];
      }
    } else if (index === 1) {
      switch (map) {
        case '1112_11-1':
          return ['85', '20'];
        case '1112_8-4':
          return ['72', '25'];
        case '1112_8-3':
          return ['68', '25'];
        case '1112_9-1':
          return ['70', '30'];
        case '1112_8-2':
          return ['65', '25'];
        case '1112_7-1':
          return ['58', '25'];
        case '1112_6-1':
          return ['55', '25'];
        case '1112_4-1':
          return ['40', '28'];
        case '1112_2-1':
          return ['33', '25'];
        case '1112_1-1':
          return ['31', '24'];
        case '1113_1-2':
          return ['62', '72'];
        case '1113_1-1':
          return ['57', '72'];
        default:
          return ['0', '0'];
      }
    } else if (index === 2) {
      switch (map) {
        case '1113_3-1':
          return ['76', '10'];
        case '1113_4-1':
          return ['76', '25'];
        case '1113_5-1':
          return ['72', '10'];
        case '1113_6-1':
          return ['72', '25'];
        case '1114_1-1':
          return ['67', '25'];
        case '1113_2-2':
          return ['62', '25'];
        case '1113_2-1':
          return ['57', '25'];
        case '1110_11-1':
          return ['40', '42'];
        case '1111_4-1':
          return ['40', '75'];
        case '1110_3-1':
          return ['45', '70'];
        case '1110_3-2':
          return ['50', '67'];
        case '1110_3-3':
          return ['55', '72'];
        case '1111_2-1':
          return ['70', '60'];
        case '1111_2-2':
          return ['70', '75'];
        case '1111_1-1':
          return ['73', '75'];
        case '1111_1-2':
          return ['73', '60'];
        case '1111_3-2':
          return ['92', '65'];
        case '1111_3-1':
          return ['92', '75'];
        default:
          return ['0', '0'];
      }
    }
  } else if (work === 'BX_US') {
    if (index === 0) {
      switch (map) {
        case '1117_9-1':
          return ['85', '75'];
        case '1115_4-3':
          return ['74', '12'];
        case '1115_4-2':
          return ['70', '13'];
        case '1115_2-9':
          return ['63', '14'];
        case '1115_2-8':
          return ['60', '14'];
        case '1115_4-1':
          return ['58', '10'];
        case '1115_2-7':
          return ['56', '13'];
        case '1115_2-6':
          return ['52', '13'];
        case '1115_2-5':
          return ['50', '13'];
        case '1115_2-4':
          return ['48', '13'];
        case '1115_2-3':
          return ['45', '13'];
        case '1115_2-2':
          return ['40', '13'];
        case '1115_2-1':
          return ['35', '13'];
        case '1115_1-9':
          return ['65', '53'];
        case '1115_1-8':
          return ['63', '53'];
        case '1115_1-7':
          return ['58', '53'];
        case '1115_1-6':
          return ['55', '53'];
        case '1115_1-5':
          return ['52', '53'];
        case '1115_1-4':
          return ['50', '60'];
        case '1115_1-3':
          return ['45', '60'];
        case '1115_1-2':
          return ['40', '60'];
        case '1115_1-1':
          return ['36', '60'];
        case '1115_5-1':
          return ['28', '60'];
        case '1117_6-1':
          return ['50', '72'];
        default:
          return ['0', '0'];
      }
    } else if (index === 1) {
      switch (map) {
        case '1117_10-1':
          return ['85', '20'];
        case '1117_8-1':
          return ['72', '25'];
        case '1117_7-1':
          return ['60', '20'];
        case '1117_5-1':
          return ['53', '20'];
        case '1117_4-1':
          return ['49', '25'];
        case '1117_3-1':
          return ['44', '19'];
        case '1117_2-1':
          return ['39', '19'];
        case '1117_1-2':
          return ['35', '19'];
        case '1117_2-2':
          return ['39', '30'];
        case '1117_1-1':
          return ['35', '30'];
        case '1118_13-1':
          return ['84', '40'];
        case '1118_14-1':
          return ['84', '68'];
        case '1118_11-1':
          return ['74', '78'];
        case '1118_10-2':
          return ['68', '79'];
        case '1118_10-1':
          return ['65', '68'];
        case '1118_6-3':
          return ['61', '63'];
        case '1118_6-2':
          return ['59', '63'];
        case '1118_6-1':
          return ['55', '63'];
        case '1118_7-1':
          return ['54', '78'];
        case '1118_7-2':
          return ['60', '78'];
        case '1118_8-3':
          return ['48', '78'];
        case '1118_8-2':
          return ['40', '78'];
        case '1118_8-1':
          return ['35', '79'];
        case '1118_9-1':
          return ['35', '68'];
        case '1118_9-3':
          return ['40', '66'];
        case '1118_9-2':
          return ['39', '55'];
        default:
          return ['0', '0'];
      }
    } else if (index === 2) {
      switch (map) {
        case '1116_7-1':
          return ['92', '68'];
        case '1116_6-1':
          return ['85', '75'];
        case '1116_5-1':
          return ['84', '55'];
        case '1118_16-1':
          return ['84', '40'];
        case '1118_15-1':
          return ['84', '15'];
        case '1118_12-1':
          return ['74', '12'];
        case '1118_5-3':
          return ['70', '13'];
        case '1118_5-2':
          return ['66', '15'];
        case '1118_5-1':
          return ['64', '18'];
        case '1118_1-4':
          return ['61', '30'];
        case '1118_2-2':
          return ['61', '8'];
        case '1118_2-1':
          return ['55', '8'];
        case '1118_1-3':
          return ['58', '25'];
        case '1118_1-2':
          return ['56', '39'];
        case '1118_1-1':
          return ['54', '25'];
        case '1118_3-3':
          return ['46', '10'];
        case '1118_3-2':
          return ['40', '10'];
        case '1118_3-1':
          return ['35', '7'];
        case '1118_4-1':
          return ['35', '25'];
        case '1118_4-3':
          return ['40', '25'];
        case '1118_4-2':
          return ['38', '28'];
        case '1116_2-2':
          return ['38', '55'];
        case '1116_2-1':
          return ['38', '75'];
        case '1116_1-2':
          return ['33', '75'];
        case '1116_1-1':
          return ['33', '55'];
        case '1115_3-2':
          return ['41', '60'];
        case '1115_3-1':
          return ['48', '60'];
        case '1116_3-1':
          return ['58', '75'];
        case '1116_4-1':
          return ['72', '75'];
        default:
          return ['0', '0'];
      }
    }
  } else if (work === 'BX_EU') {
    if (index === 0) {
      switch (map) {
        case '1121_10-1':
          return ['85', '85'];
        case '1119_4-3':
          return ['74', '12'];
        case '1119_4-2':
          return ['70', '13'];
        case '1119_2-9':
          return ['63', '14'];
        case '1119_2-8':
          return ['60', '14'];
        case '1119_4-1':
          return ['58', '3'];
        case '1119_2-7':
          return ['58', '13'];
        case '1119_2-6':
          return ['55', '13'];
        case '1119_2-5':
          return ['50', '13'];
        case '1119_2-4':
          return ['48', '13'];
        case '1119_2-3':
          return ['45', '13'];
        case '1119_2-2':
          return ['40', '13'];
        case '1119_2-1':
          return ['35', '13'];
        case '1119_1-9':
          return ['65', '60'];
        case '1119_1-8':
          return ['63', '60'];
        case '1119_1-7':
          return ['58', '60'];
        case '1119_1-6':
          return ['55', '60'];
        case '1119_1-5':
          return ['52', '60'];
        case '1119_1-4':
          return ['50', '60'];
        case '1121_7-1':
          return ['50', '75'];
        case '1119_1-3':
          return ['45', '60'];
        case '1119_1-2':
          return ['40', '60'];
        case '1119_1-1':
          return ['35', '60'];
        case '1119_5-1':
          return ['30', '60'];
        default:
          return ['0', '0'];
      }
    } else if (index === 1) {
      switch (map) {
        case '1121_11-1':
          return ['85', '20'];
        case '1121_9-1':
          return ['72', '25'];
        case '1121_8-1':
          return ['60', '20'];
        case '1121_4-1':
          return ['50', '20'];
        case '1121_6-1':
          return ['47', '20'];
        case '1121_5-1':
          return ['48', '30'];
        case '1121_3-1':
          return ['39', '19'];
        case '1121_2-2':
          return ['35', '19'];
        case '1121_3-2':
          return ['39', '30'];
        case '1121_2-1':
          return ['30', '30'];
        case '1122_13-1':
          return ['84', '40'];
        case '1121_1-1':
          return ['84', '40'];
        case '1122_14-1':
          return ['84', '68'];
        case '1122_11-1':
          return ['74', '78'];
        case '1122_10-2':
          return ['68', '79'];
        case '1122_10-1':
          return ['65', '68'];
        case '1122_6-3':
          return ['61', '63'];
        case '1122_6-2':
          return ['59', '63'];
        case '1122_6-1':
          return ['55', '63'];
        case '1122_9-3':
          return ['40', '63'];
        case '1122_9-2':
          return ['38', '60'];
        case '1122_9-1':
          return ['35', '78'];
        case '1122_8-1':
          return ['40', '78'];
        case '1122_8-2':
          return ['40', '79'];
        case '1122_8-3':
          return ['45', '78'];
        case '1122_7-1':
          return ['55', '78'];
        case '1122_7-2':
          return ['60', '78'];
        default:
          return ['0', '0'];
      }
    } else if (index === 2) {
      switch (map) {
        case '1120_13-1':
          return ['92', '68'];
        case '1116_6-1':
          return ['85', '75'];
        case '1120_10-1':
          return ['84', '55'];
        case '1122_16-1':
          return ['84', '40'];
        case '1122_15-1':
          return ['84', '15'];
        case '1122_12-1':
          return ['73', '13'];
        case '1122_5-3':
          return ['70', '15'];
        case '1122_5-2':
          return ['67', '18'];
        case '1122_5-1':
          return ['64', '18'];
        case '1122_1-4':
          return ['61', '30'];
        case '1122_2-2':
          return ['61', '8'];
        case '1122_2-1':
          return ['55', '8'];
        case '1122_1-3':
          return ['58', '25'];
        case '1122_1-2':
          return ['56', '39'];
        case '1122_1-1':
          return ['54', '25'];
        case '1122_3-3':
          return ['46', '10'];
        case '1122_3-2':
          return ['40', '10'];
        case '1122_3-1':
          return ['35', '7'];
        case '1122_4-1':
          return ['35', '25'];
        case '1122_4-2':
          return ['38', '30'];
        case '1122_4-3':
          return ['40', '28'];
        case '1120_11-1':
          return ['82', '60'];
        case '1120_11-2':
          return ['82', '75'];
        case '1120_9-2':
          return ['78', '75'];
        case '1120_9-1':
          return ['78', '60'];
        case '1120_8-1':
          return ['73', '75'];
        case '1120_7-1':
          return ['58', '75'];
        case '1120_6-1':
          return ['50', '75'];
        case '1120_4-1':
          return ['45', '75'];
        case '1120_5-1':
          return ['47', '70'];
        case '1119_3-1':
          return ['47', '65'];
        case '1119_3-2':
          return ['40', '68'];
        case '1120_3-2':
          return ['38', '65'];
        case '1120_2-1':
          return ['35', '65'];
        case '1120_3-1':
          return ['38', '75'];
        case '1120_2-2':
          return ['35', '75'];
        case '1120_1-1':
          return ['30', '62'];
        default:
          return ['0', '0'];
      }
    }
  }
  return ['0', '0'];
};

export const convertTitleFromMap = (map: string): string => {
  return map.replace('_', ' ');
};
