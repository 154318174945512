import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import {LOCATIONID_DEVELOPMENT_RESULTS, PAGEID_DEVELOPMENT_RESULTS} from '_logics/LFCPageId';
import {handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {
  MenuItem,
  Select,
  Switch,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  Box
} from '@mui/material';
import LFCTextField from '_components/inputs/LFCTextField';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useEffect} from 'react';
import dayjs from 'dayjs';
import LFCSelectFormWork from '_components-with-data/inputs/LFCSelectFormWork';
import LFCSelectFormDeviceId from '_components-with-data/inputs/LFCSelectFormDeviceId';

interface ResultsSearchBarProps {
  formRef: any;
  searchValue: any;
  t: any;
  setSearchValue: any;
  autoOnChange: any;
  doSearch: any;
  resetSearch: any;
  openSavedCondition: any;
  setOpenSavedCondition: any;
  condition: any;
  onLoadSavedCondition: any;
  onSelectSavedCondition: any;
  onChangeShowLatestOnly: any;
  onChangeAutoReload: any;
  onSelectAutoReloadInterval: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  displayPrevData: any;
  displayNextData: any;
  data: any[][];
  index: number;
  workParam: any[];
  startFetchingData: any;
  setStartFetchingData: any;
  serial: any;
  deviceId: any;
  setSerial: any;
  setWorkParam: any;
  setDeviceId: any;
  serialNumber: string;
  isDisplayNgOnly: boolean;
  toggleDisplayNgOnly: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setOpenDataScreenShot: any;
  isDisplayName: boolean;
  toggleDisplayName: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ResultsSearchBar = ({
  formRef,
  searchValue,
  setSearchValue,
  resetSearch,
  openSavedCondition,
  setOpenSavedCondition,
  condition,
  onLoadSavedCondition,
  onSelectSavedCondition,
  onChangeAutoReload,
  onSelectAutoReloadInterval,
  t,
  setOpen,
  open,
  doSearch,
  displayNextData,
  displayPrevData,
  data,
  index,
  workParam,
  startFetchingData,
  setStartFetchingData,
  serial,
  deviceId,
  serialNumber,
  isDisplayNgOnly,
  toggleDisplayNgOnly,
  setOpenDataScreenShot,
  isDisplayName,
  toggleDisplayName
}: ResultsSearchBarProps) => {
  const handleLatestButtonClick = () => {
    setOpenDataScreenShot('');
    doSearch();
  };
  const handleEnterPress = (event: {key: string; preventDefault: () => void}) => {
    if (event.key === 'Enter' && !open) {
      handleLatestButtonClick();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleEnterPress);
    return () => {
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [open, searchValue]);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    const resetTimer = () => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        const textField = document.querySelector('[name="serialno"]');
        if (textField) {
          (textField as HTMLElement).focus();
        }
      }, 5000);
    };

    resetTimer();

    const onActivity = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', onActivity);
    window.addEventListener('keydown', onActivity);

    return () => {
      window.removeEventListener('mousemove', onActivity);
      window.removeEventListener('keydown', onActivity);
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    if (startFetchingData) {
      setTimeout(() => {
        setSearchValue({
          ...searchValue,
          work: [workParam],
          deviceid: [deviceId],
          serialno: serial
        });
        // setAutoCompleteReset(true);
        setStartFetchingData(false);
      }, 100);
    }
  }, [startFetchingData]);
  return (
    <Grid container>
      <Grid item xs={12} justifyContent="center" display="flex">
        <form ref={formRef}>
          <LFCFormRowGroup alignCenter>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor={index === data.length - 1 ? 'gray' : '#bce2e8'}
              borderRadius="10px"
              width="100px"
              height="80px"
              sx={{
                cursor: index < data.length - 1 ? 'pointer' : 'default'
              }}
              onClick={() => {
                index < data.length - 1 && displayPrevData();
              }}
            >
              <Typography>ひとつ前</Typography>
              <ArrowBackIosIcon />
            </Box>
            <LFCDatetimePicker
              name={'select_datetime'}
              label={t('検査日時')}
              value={
                dayjs(
                  data?.[index]
                    ?.slice()
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(b?.lq_time).getTime() - new Date(a?.lq_time).getTime()
                    )?.[0]?.lq_time
                ).format('YYYY-MM-DD HH:mm') ?? undefined
              }
              disabled
            />
            <LFCSelectFormWork
              name={t('work')}
              label={`${t('機種')}`}
              value={searchValue.work}
              onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
              multiple={false}
            />
            <LFCSelectFormDeviceId
              name={'deviceid'}
              label={`${t('検査装置')}`}
              value={searchValue.deviceid}
              onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
              multiple={false}
            />
            <LFCTextField
              name="serialno"
              label={t('シリアルNo')}
              value={searchValue.serialno}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
                setSearchValue({
                  ...searchValue,
                  serialno: event?.target.value
                });
              }}
              autoFocus
            />
            <LFCButton
              color="primary"
              onClick={() => handleLatestButtonClick()}
              style={{height: '40px'}}
              type="button"
            >
              {t('最新')}
            </LFCButton>
            <LFCButton color="primary" onClick={() => setOpen(true)} style={{height: '40px'}}>
              {t('一覧表示')}
            </LFCButton>
            <LFCButton onClick={() => resetSearch()} style={{height: '40px'}}>
              {t('リセット')}
            </LFCButton>
            <LFCSavedCondition
              open={openSavedCondition}
              pageId={PAGEID_DEVELOPMENT_RESULTS}
              locationNo={LOCATIONID_DEVELOPMENT_RESULTS}
              onLoad={onLoadSavedCondition}
              onSelect={onSelectSavedCondition}
              onClose={() => setOpenSavedCondition(false)}
              conditions={condition}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor={index === 0 ? 'gray' : '#bce2e8'}
              borderRadius="10px"
              width="100px"
              height="80px"
              sx={{
                cursor: index === 0 ? 'default' : 'pointer'
              }}
              onClick={() => {
                index > 0 && displayNextData();
              }}
            >
              <Typography>ひとつ後</Typography>
              <ArrowForwardIosIcon />
            </Box>

            <FormControlLabel
              label="NGのみ"
              labelPlacement="top"
              control={
                <Switch
                  checked={isDisplayNgOnly}
                  onChange={toggleDisplayNgOnly}
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
            />
            <FormControlLabel
              label="名前表示"
              labelPlacement="top"
              control={
                <Switch
                  checked={isDisplayName}
                  onChange={toggleDisplayName}
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column'
              }}
            >
              <FormControlLabel
                label="自動更新"
                labelPlacement="start"
                control={
                  <Switch
                    checked={searchValue.auto_reload}
                    onChange={onChangeAutoReload}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                }
              />
              <Box display="flex" marginLeft={5}>
                <FormControl>
                  <Select
                    value={searchValue.auto_reload_interval}
                    onChange={onSelectAutoReloadInterval}
                    displayEmpty
                    inputProps={{'aria-label': 'auto-reload-interval'}}
                    style={{height: '40px', width: '80px'}}
                    disabled={!searchValue.auto_reload}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={120}>120</MenuItem>
                    <MenuItem value={300}>300</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: 5,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  秒
                </Typography>
              </Box>
            </div>
          </LFCFormRowGroup>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography fontSize="48px">表示中のシリアルNo:</Typography>
            <Typography fontSize="48px">{serialNumber}</Typography>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};
