import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  };
  yLeft: {
    type: string;
    name: string;
    dsColumn: any;
    markPoint?: any;
    markLine?: any;
  };
  yRight: {
    type: string;
    name: string;
    dsColumn: any;
    markPoint?: any;
    markLine?: any;
  };
  grid?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  color?: string[];
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  onClick?: (prm: {data: any}) => void;
  onDoubleClick?: (prm: {data: any}) => void;
  inputScale?: boolean;
}

/**
 * LFCChartsComboYMD
 * @param props
 * @returns
 */
const LFCChartsComboYMD = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);
  const [myGrid, setMyGrid] = useState({
    top: 65,
    left: 60,
    right: 65,
    bottom: 93
  });

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  useEffect(() => {
    if (!props.grid) return;

    let newGrid: any = myGrid;
    Object.entries(props.grid).forEach(([key, value]: [string, number]) => {
      if (value) newGrid[key] = value;
    });
    setMyGrid(newGrid);
  }, [props.grid]);

  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: myGrid,
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params: any) {
              return (
                params[0].name +
                '<br/>' +
                t('検査数(個)') +
                params[0].value.total_count +
                '<br/>' +
                t('不良率(%)') +
                ((params[0].value.ng / params[0].value.total_count) * 100).toFixed(1) +
                '<br/>'
              );
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          color: props.color ? props.color : null,
          dataset: {
            source: props.source
          },
          xAxis: [
            {
              type: 'category',
              axisLabel: {
                rotate: '45',
                // fontSize: 10,
                width: myGrid.bottom,
                overflow: 'truncate'
              },
              data: (() => {
                let list = [];
                let dt = props.x.startDate;
                for (let i = 0; dt.add(i, 'd') <= props.x.endDate; i++) {
                  list.push(dt.add(i, 'd').format('YYYY/MM/DD'));
                }
                return list;
              })()
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: props.yLeft.name,
              overflow: 'truncate',
              nameTextStyle: {
                color: props.color ? props.color[0] : null,
                align: 'left'
              },
              axisLabel: {
                color: props.color ? props.color[0] : null,
                width: myGrid.left,
                overflow: 'truncate'
              },
              axisLine: {
                lineStyle: {
                  color: props.color ? props.color[0] : null
                }
              },
              axisTick: {
                lineStyle: {
                  color: props.color ? props.color[0] : null
                }
              },
              min: scaleValue.y1AxisMin,
              max: scaleValue.y1AxisMax,
              interval: scaleValue.y1AxisInterval
            },
            {
              type: 'value',
              name: props.yRight.name,
              nameTextStyle: {
                color: props.color ? props.color[1] : null,
                align: 'right'
              },
              axisLabel: {
                color: props.color ? props.color[1] : null,
                width: myGrid.right,
                overflow: 'truncate'
              },
              axisLine: {
                lineStyle: {
                  color: props.color ? props.color[1] : null
                }
              },
              axisTick: {
                lineStyle: {
                  color: props.color ? props.color[1] : null
                }
              },
              min: scaleValue.y2AxisMin,
              max: scaleValue.y2AxisMax,
              interval: scaleValue.y2AxisInterval,
              splitLine: {
                show: false
              }
            }
          ],
          series: [
            {
              type: props.yLeft.type,
              name: props.yLeft.name,
              xAxisIndex: 0,
              yAxisIndex: 0,
              encode: {
                // x: props.x.dsColumn,
                y: props.yLeft.dsColumn
              },
              markPoint: props.yLeft.markPoint,
              // markLine: props.yLeft.markLine,
              markLine: {
                data: props.yLeft.markLine,
                silent: false,
                animation: true
              }
            },
            {
              type: props.yRight.type,
              name: props.yRight.name,
              xAxisIndex: 0,
              yAxisIndex: 1,
              encode: {
                // x: props.x.dsColumn,
                y: props.yRight.dsColumn
              },
              markPoint: props.yRight.markPoint,
              markLine: {
                data: props.yRight.markLine.data,
                symbol: props.yRight.markLine.symbol,
                silent: false,
                animation: true
              }
            }
          ],
          animation: true
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) props.onClick(params);
          },
          dblclick: (params: any) => {
            if (props.onDoubleClick != null) props.onDoubleClick(params);
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yLeft.name}
        y2AxisName={props.yRight.name}
        inputY1Axis={true}
        inputY2Axis={true}
      />
    </div>
  );
};
export default LFCChartsComboYMD;
