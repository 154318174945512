import {useTheme} from '@mui/material';

/**
 * 引数
 */
interface Props {
  title: string;
  children: React.ReactNode;
}

const LFCTitleFrame = (props: Props) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        minWidth: '0',
        padding: '0',
        margin: '0',
        border: '0',
        verticalAlign: 'top',
        boxSizing: 'inherit'
      }}
    >
      <label
        style={{
          color: theme.palette.text.secondary,
          padding: 0,
          display: 'block',
          transformOrigin: 'top left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(133% - 24px)',
          position: 'absolute',
          left: 0,
          top: 0,
          transform: 'translate(14px, -9px) scale(0.75)',
          transition: `
                  color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
                  transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
                  max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
                `,
          zIndex: 1,
          pointerEvents: 'auto',
          userSelect: 'none',
          boxSizing: 'inherit'
        }}
      >
        {props.title}
      </label>
      <div
        style={{
          boxSizing: 'border-box',
          cursor: 'text',
          display: 'inline-flex',
          alignItems: 'center',
          position: 'relative',
          borderRadius: '4px',
          padding: '0px 14px'
        }}
      >
        {props.children}
        <fieldset
          style={{
            textAlign: 'left',
            position: 'absolute',
            bottom: '0',
            right: '0',
            top: '-5px',
            left: '0',
            margin: '0',
            padding: '0 8px',
            pointerEvents: 'none',
            borderRadius: 'inherit',
            borderStyle: 'solid',
            borderWidth: '1px',
            overflow: 'hidden',
            minWidth: '0%',
            borderColor:
              theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
            boxSizing: 'inherit'
          }}
        >
          <legend
            style={{
              float: 'unset',
              width: 'auto',
              overflow: 'hidden',
              display: 'block',
              padding: 0,
              height: '11px',
              fontSize: '0.75em',
              visibility: 'hidden',
              maxWidth: '100%',
              transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
              whiteSpace: 'nowrap',
              boxSizing: 'inherit'
            }}
          >
            <span
              style={{
                paddingLeft: '5px',
                paddingRight: '15px',
                display: 'inline-block',
                opacity: '0',
                visibility: 'visible',
                boxSizing: 'inherit',
                fontSize: '0.75em',
                whiteSpace: 'nowrap'
              }}
            >
              {props.title}
            </span>
          </legend>
        </fieldset>
      </div>
    </div>
  );
};

export default LFCTitleFrame;
