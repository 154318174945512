import LFCConfirmDialog from '_components/feedback/LFCConfirmDialog';
import LFCButton from '_components/inputs/LFCButton';
import ProgressBar from '_components/ProgressBar';
import {adminAPIAccess} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import awsConfiguration from 'awsConfiguration';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Grid, Stack, Typography} from '@mui/material';
import {DataGridPro, GridColumns, GridRowId, useGridApiRef} from '@mui/x-data-grid-pro';

import UserUpsertDialog, {emptyRowData, rowData} from './components/UserUpsertDialog';

const UserMaintenance = () => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const apiRef = useGridApiRef();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [screenLock, setScreenLock] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = useState(false);
  const [limitUserCount, setLimitUserCount] = useState(0);

  const [userUpsertDialogRow, setUserUpsertDialogRow] = useState<rowData>(emptyRowData);
  const [isDlgOpen, setIsDlgOpen] = useState(false);

  const [source] = useState(axios.CancelToken.source());

  // 削除メッセージ作成
  const getConfirmMessgae = () => {
    const user_id_list: string[] = selectionModel.map((id, idx) => {
      return dgRows.filter((v: any) => v.id === id)[0].user_id;
    });
    return `${t('message.削除しますか？')}[ ${user_id_list.join(', ')} ]`;
  };

  // 初期検索、最新の情報に更新ボタン押下時
  const onSearchClick = () => {
    setSelectionModel([]);

    setStartProcess(true);

    // Cognitoのユーザリストを取得
    adminAPIAccess({
      apipath: 'GetUserList',
      parameters: {
        user_pool_id: awsConfiguration.UserPoolId
      },
      cancelToken: source.token
    })
      .then(response => {
        setLimitUserCount(response.data.account_limit);
        const userData: any[] = response.data.datas.map((item: any, idx: any) => {
          return {
            id: String(idx),
            user_id: String(item['user_id']),
            user_name: String(item['user_name']),
            email: String(item['email']),
            information_access_timestamp: String(item['information_access_timestamp'] || '')
          };
        });
        setDgRows(userData);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(t(`aws.cognito.${error.message}`), {variant: 'error'});
        }
      })
      .finally(() => {
        setStartProcess(false);
      });
  };

  // 削除ボタン押下時
  const onDelClick = () => {
    setOpenDeleteConfirmDlg(true);
  };

  // 削除確認ダイアログでOKボタン押下時
  const deleteCognitoUser = () => {
    const delete_users: string[] = selectionModel.map((id, idx) => {
      return dgRows.filter((v: any) => v.id === id)[0].user_id;
    });

    setScreenLock(true);

    adminAPIAccess({
      apipath: 'DeleteUser',
      parameters: {
        user_pool_id: awsConfiguration.UserPoolId,
        target_users: delete_users
      },
      cancelToken: source.token
    })
      .then(response => {
        // エラー判定
        switch (response.data.result_code) {
          case 0:
            enqueueSnackbar(t('削除') + t('message.が完了しました。'), {variant: 'success'});
            onSearchClick();
            break;
          case -1:
            enqueueSnackbar(t('message.自身は削除出来ません'), {variant: 'error'});
            break;
          default:
            throw new Error(
              `result_code:${response.data.result_code} / result_ex:${response.data.result_ex}`
            );
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(t(`aws.cognito.${error.message}`), {variant: 'error'});
        }
      })
      .finally(() => {
        setScreenLock(false);
      });
  };

  // 新規ボタン押下時
  const onNewClick = () => {
    // setUserUpsertDialogRow({
    //   mode: 'AddNew',
    //   company_id: '1',
    //   use_type: '10',
    //   email: '',
    //   user_id: '',
    //   user_name: '',
    //   locale: 'ja',
    //   effective_date: '9999-12-31'
    // });
    setIsDlgOpen(true);
  };

  // 新規入力ダイアログクローズ時
  const onDlgClose = (isOk: boolean) => {
    setIsDlgOpen(false);
    if (isOk) {
      onSearchClick();
    }
  };

  // マウント時処理
  useEffect(() => {
    setDgColumns([
      {field: 'id', headerName: 'No', description: '', hide: true},
      {
        field: 'user_id',
        headerName: `${t('ユーザID')}`,
        description: `${t('ユーザID')}`,
        width: 200
      },
      {
        field: 'user_name',
        headerName: `${t('ユーザー名')}`,
        description: `${t('ユーザー名')}`,
        width: 200
      },
      {
        field: 'email',
        headerName: `${t('メールアドレス')}`,
        description: `${t('メールアドレス')}`,
        width: 500
      },
      {
        field: 'information_access_timestamp',
        headerName: `${t('最終アクセス')}`,
        description: `${t('最終アクセス')}`,
        width: 200
      }
    ]);

    onSearchClick();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setStartProcess(screenLock);
  }, [screenLock]);

  return (
    <GenericTemplate title={t('menu.page_name.ユーザ管理')}>
      <ProgressBar startProcess={startProcess} screenLock={screenLock} />
      <form ref={formRef}>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={6}>
            <LFCButton color="primary" onClick={onSearchClick}>
              {t('message.最新の情報に更新')}
            </LFCButton>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography>{`${t('総ユーザ数')}：${dgRows.length} / ${limitUserCount}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={{height: '75vh'}}>
              <DataGridPro
                apiRef={apiRef}
                columns={dgColumns}
                rows={dgRows}
                density={'compact'}
                onSelectionModelChange={setSelectionModel}
                selectionModel={selectionModel}
                showCellRightBorder
                showColumnRightBorder
                checkboxSelection
                disableSelectionOnClick
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <LFCButton color="primary" onClick={onNewClick}>
                {t('新規')}
              </LFCButton>
              <LFCButton
                color="secondary"
                onClick={onDelClick}
                disabled={selectionModel.length === 0}
              >
                {t('削除')}
              </LFCButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <LFCConfirmDialog
        open={openDeleteConfirmDlg}
        message={getConfirmMessgae()}
        onClose={isOK => {
          setOpenDeleteConfirmDlg(false);
          isOK && deleteCognitoUser();
        }}
      ></LFCConfirmDialog>
      <UserUpsertDialog open={isDlgOpen} onClose={onDlgClose} row={userUpsertDialogRow} />
    </GenericTemplate>
  );
};
export default UserMaintenance;
