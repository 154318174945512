import {getLFCSessionStorage} from '_contexts/LFCSessionStorage';
import {getSessionSync} from '_logics/LFCCognito';
import axios from 'axios';
import {OptionsObject, SnackbarKey, SnackbarMessage} from 'notistack';

import {SelectChangeEvent} from '@mui/material';

/**
 * データ取得WebAPI
 * @param prm
 * @returns
 */
export const getLFCData = async (prm: {
  snack?: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  sql_id: Number;
  parameters: any;
  ds_state?: any;
  name?: string;
  cancelToken?: any;
  t?: any;
  rep_company_id?: string; // 自社以外の会社DBにアクセスする場合に利用。ただし会社ID=1のLINKWIZ所属メンバーのみアクセス可能
}): Promise<any> => {
  const cognitoSession = await getSessionSync();

  console.debug(`${getLFCSessionStorage().webapiBaseUrl}/GetLFCData`, prm.sql_id); //todo:

  return await axios
    .post(
      `${getLFCSessionStorage().webapiBaseUrl}/GetLFCData`,
      {
        sql_id: prm.sql_id,
        parameters: prm.parameters,
        is_dict_responce: true, // dict形式で返す
        rep_company_id: prm.rep_company_id ?? ''
      },
      {
        cancelToken: prm.cancelToken,
        headers: {
          Authorization: cognitoSession?.getIdToken().getJwtToken()
        }
      }
    )
    .then(response => {
      // エラー判定
      switch (response.data.result_code) {
        case 0:
          if (response.data.datas.length === 0 && prm.name) {
            console.warn(`データがありません。[${prm.sql_id}]`);
            prm.snack &&
              prm.snack(`${prm.t('message.データがありません。')}[${prm.name}]`, {
                variant: 'warning'
              });
          }
          break;
        case 100:
          prm.snack &&
            prm.snack(
              `${prm.t('message.データの取得上限を超えたため、一部のデータを表示しています。')}[${
                prm.name
              }]`,
              {
                variant: 'warning'
              }
            );
          break;
        default:
          throw new Error(`result_ex：${response.data.result_ex}`);
      }
      //戻り値をパラメータにもセット
      if (prm.ds_state != null) {
        prm.ds_state(response.data.datas);
      }

      return response.data.datas;
    })
    .catch(error => {
      let mes = '';
      if (axios.isCancel(error)) {
        console.warn(error);
        throw new Error(error);
      } else if (error.response?.status === 401) {
        // 認証エラー
        mes = `${prm.t('message.セッションの有効期限が切れました。再度ログインしてください。')}`;
        prm.snack && prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      } else {
        console.error(error);
        console.error(`サーバ側でエラーが発生しました。sql_id=${prm.sql_id}`);
        mes = `${prm.t('message.エラーが発生しました。')}sql_id=${prm.sql_id}`;
        prm.snack && prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      }
    });
};
/**
 * データ取得WebAPI(CSV形式)
 * @param prm
 * @returns
 */
export const getLFCDataCSV = async (prm: {
  snack?: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  sql_id: Number;
  parameters: any;
  ds_state?: any;
  name?: string;
  cancelToken?: any;
  t?: any;
  rep_company_id?: string; // 自社以外の会社DBにアクセスする場合に利用。ただし会社ID=1のLINKWIZ所属メンバーのみアクセス可能
}): Promise<any> => {
  const cognitoSession = await getSessionSync();

  return await axios
    .post(
      `${getLFCSessionStorage().webapiBaseUrl}/GetLFCData`,
      {
        sql_id: prm.sql_id,
        parameters: prm.parameters,
        is_dict_responce: false, // CSV形式で返す
        rep_company_id: prm.rep_company_id ?? ''
      },
      {
        cancelToken: prm.cancelToken,
        headers: {
          Authorization: cognitoSession?.getIdToken().getJwtToken()
        }
      }
    )
    .then(response => {
      // エラー判定
      switch (response.data.result_code) {
        case 0:
          if (response.data.datas.length <= 1 && prm.name) {
            console.warn(`データがありません。[${prm.sql_id}]`);
            prm.snack &&
              prm.snack(`${prm.t('message.データがありません。')}[${prm.name}]`, {
                variant: 'warning'
              });
          }
          break;
        case 100:
          prm.snack &&
            prm.snack(
              `${prm.t('message.データの取得上限を超えたため、一部のデータを表示しています。')}[${
                prm.name
              }]`,
              {
                variant: 'warning'
              }
            );
          break;
        default:
          throw new Error(`result_ex：${response.data.result_ex}`);
      }
      //戻り値をパラメータにもセット
      if (prm.ds_state != null) {
        prm.ds_state(response.data.datas);
      }

      return response.data.datas;
    })
    .catch(error => {
      let mes = '';
      if (axios.isCancel(error)) {
        console.warn(error);
        throw new Error(error);
      } else if (error.response?.status === 401) {
        // 認証エラー
        mes = `${prm.t('message.セッションの有効期限が切れました。再度ログインしてください。')}`;
        prm.snack && prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      } else {
        console.error(error);
        console.error(`サーバ側でエラーが発生しました。sql_id=${prm.sql_id}`);
        mes = `${prm.t('message.エラーが発生しました。')}sql_id=${prm.sql_id}`;
        prm.snack && prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      }
    });
};
/**
 * データ取得WebAPI(処理付き)
 * @param prm
 * @returns
 */
export const getLFCDataProc = async (prm: {
  snack?: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  invoke_name: string;
  parameters: any;
  ds_state?: any;
  name?: string;
  cancelToken?: any;
  t?: any;
}): Promise<any> => {
  const cognitoSession = await getSessionSync();

  return await axios
    .post(
      `${getLFCSessionStorage().webapiBaseUrl}/GetLFCDataProc`,
      {
        invoke_name: prm.invoke_name,
        parameters: prm.parameters
      },
      {
        cancelToken: prm.cancelToken,
        headers: {
          Authorization: cognitoSession?.getIdToken().getJwtToken()
        }
      }
    )
    .then(response => {
      // エラー判定
      switch (response.data.result_code) {
        case 0:
          if (response.data.datas.length <= 1 && prm.name) {
            console.warn(`データがありません。[${prm.invoke_name}]`);
            prm.snack &&
              prm.snack(`${prm.t('message.データがありません。')}[${prm.name}]`, {
                variant: 'warning'
              });
          }
          break;
        case 100:
          prm.snack &&
            prm.snack(
              `${prm.t('message.データの取得上限を超えたため、一部のデータを表示しています。')}[${
                prm.name
              }]`,
              {
                variant: 'warning'
              }
            );
          break;
        default:
          throw new Error(`result_ex：${response.data.result_ex}`);
      }
      //戻り値をパラメータにもセット
      if (prm.ds_state != null) {
        prm.ds_state(response.data.datas);
      }

      return response.data.datas;
    })
    .catch(error => {
      let mes = '';
      if (axios.isCancel(error)) {
        console.warn(error);
        throw new Error(error);
      } else if (error.response?.status === 401) {
        // 認証エラー
        mes = `${prm.t('message.セッションの有効期限が切れました。再度ログインしてください。')}`;
        prm.snack && prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      } else {
        console.error(error);
        console.error(`サーバ側でエラーが発生しました。invoke_name=${prm.invoke_name}`);
        mes = `${prm.t('message.エラーが発生しました。')}invoke_name=${prm.invoke_name}`;
        prm.snack && prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      }
    });
};
/**
 *データ追加更新削除WebAPI
 * @param prm
 * @returns
 */
export const setLFCData = async (prm: {
  snack: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  sql_id: Number;
  parameters: any;
  ds_state?: any;
  name?: string;
  cancelToken?: any;
  t?: any;
}): Promise<any> => {
  const cognitoSession = await getSessionSync();

  return await axios
    .post(
      `${getLFCSessionStorage().webapiBaseUrl}/SetLFCData`,
      {
        sql_id: prm.sql_id,
        parameters: prm.parameters
      },
      {
        cancelToken: prm.cancelToken,
        headers: {Authorization: cognitoSession?.getIdToken().getJwtToken()}
      }
    )
    .then(response => {
      // エラー判定
      switch (response.data.result_code) {
        case 0:
          // 正常
          if (prm.name) {
            prm.snack(`${prm.t(prm.name)}${prm.t('message.が完了しました。')}`, {variant: 'info'});
          }
          break;
        default:
          throw new Error(`result_ex：${response.data.result_ex}`);
      }

      //戻り値をパラメータにもセット
      if (prm.ds_state != null) {
        prm.ds_state(response.data.datas);
      }

      return response.data.datas;
    })
    .catch(error => {
      console.log(error);
      let mes = '';
      if (axios.isCancel(error)) {
        console.warn(error);
        throw new Error(error);
      } else if (error.response?.status === 401) {
        // 認証エラー
        mes = `${prm.t('message.セッションの有効期限が切れました。再度ログインしてください。')}`;
        prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      } else {
        console.error(error);
        console.error(`サーバ側でエラーが発生しました。sql_id=${prm.sql_id}`);
        mes = `${prm.t('message.エラーが発生しました。')}sql_id=${prm.sql_id}`;
        prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      }
    });
};

/**
 * データ追加更新削除WebAPI(全環境適用 ！！利用注意！！)
 * @param prm
 * @returns
 */
export const setLFCDataMulti = async (prm: {
  snack: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  sql_id: Number;
  parameters: any;
  ds_state?: any;
  name?: string;
  cancelToken?: any;
  t?: any;
}): Promise<any> => {
  const cognitoSession = await getSessionSync();

  return await axios
    .post(
      `${getLFCSessionStorage().webapiBaseUrl}/SetLFCDataMulti`,
      {
        sql_id: prm.sql_id,
        parameters: prm.parameters
      },
      {
        cancelToken: prm.cancelToken,
        headers: {Authorization: cognitoSession?.getIdToken().getJwtToken()}
      }
    )
    .then(response => {
      // エラー判定
      console.log(`response.data.result_code= ${response.data.result_code}`);
      switch (response.data.result_code) {
        case 0:
          // 正常
          if (prm.name) {
            prm.snack(`${prm.t(prm.name)}${prm.t('message.が完了しました。')}`, {variant: 'info'});
          }
          break;
        default:
          throw new Error(`result_ex：${response.data.result_mes}`);
      }

      //戻り値をパラメータにもセット
      if (prm.ds_state != null) {
        prm.ds_state(response.data.datas);
      }

      return response.data.datas;
    })
    .catch(error => {
      console.log(error);
      let mes = '';
      if (axios.isCancel(error)) {
        console.warn(error);
        throw new Error(error);
      } else if (error.response?.status === 401) {
        // 認証エラー
        mes = `${prm.t('message.セッションの有効期限が切れました。再度ログインしてください。')}`;
        prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      } else {
        console.error(error);
        console.error(`サーバ側でエラーが発生しました。sql_id=${prm.sql_id}`);
        mes = `${prm.t('message.エラーが発生しました。')}sql_id=${prm.sql_id}`;
        prm.snack(mes, {variant: 'error'});
        throw new Error(mes);
      }
    });
};

/**
 * Admin系WebAPI呼び出し
 * @param prm
 * @returns
 */
export const adminAPIAccess = async (prm: {
  apipath: string;
  parameters: {};
  cancelToken?: any;
}): Promise<any> => {
  const cognitoSession = await getSessionSync();
  return await axios.post(
    `${getLFCSessionStorage().webapiBaseUrl}/${prm.apipath}`,
    prm.parameters,
    {
      cancelToken: prm.cancelToken,
      headers: {
        Authorization: cognitoSession?.getIdToken().getJwtToken()
      }
    }
  );
};

/**
 * SORACOM系WebAPI呼び出し
 * @param prm
 * @returns
 */
export const soracomAPIAccess = async (prm: {
  method: 'GET' | 'POST' | 'DELETE';
  apipath: string;
  parameters?: {};
  cancelToken?: any;
}): Promise<any> => {
  const cognitoSession = await getSessionSync();
  return await axios.request({
    url: `${getLFCSessionStorage().webapiBaseUrl}/Soracom/${prm.apipath}`,
    method: prm.method,
    headers: {
      Authorization: cognitoSession?.getIdToken().getJwtToken()
    },
    params: prm.method !== 'POST' ? prm.parameters : {},
    data: prm.method === 'POST' ? prm.parameters : {},
    cancelToken: prm.cancelToken
  });
};

/**
 * OpenAIChat WebAPI呼び出し
 * @param prm
 * @returns
 */
export const aiChatAPIAccess = async (prm: {
  apipath: string;
  parameters: {};
  cancelToken?: any;
}): Promise<any> => {
  const cognitoSession = await getSessionSync();
  return await axios.post(
    `${getLFCSessionStorage().webapiBaseUrl}/Openai/${prm.apipath}`,
    prm.parameters,
    {
      cancelToken: prm.cancelToken,
      headers: {
        Authorization: cognitoSession?.getIdToken().getJwtToken()
      }
    }
  );
};

/**
 * Inputデータ保持
 * @param event
 * @param getter
 * @param setter
 */
export const handleInputChange = (
  event: React.ChangeEvent<any> | undefined,
  getter: any,
  setter: any
) => {
  if (!event) {
    return;
  }
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  setter({...getter, [target.name]: value});
};
/**
 * Inputデータ保持(SelectForm用)
 * @param event
 * @param getter
 * @param setter
 */
export const handleInputChange2 = (event: SelectChangeEvent<any>, getter: any, setter: any) => {
  if (event.target.name != null) {
    setter({...getter, [event.target.name]: event.target.value});
  }
};
/**
 * Inputデータ保持(SelectForm マルチセレクト用)
 * @param event
 */
export const handleChangeMultipleUtil = (setObject: any, valueObject: any, event: any, t?: any) => {
  setObject({
    ...valueObject,
    [event.target.name]: event.target.value.filter((item: string) => item !== t('すべて'))
  });

  if (
    event.target.value.findIndex((item: string) => item === t('すべて')) > 0 ||
    event.target.value.filter((item: string) => item !== t('すべて')).length === 0
  ) {
    setObject({...valueObject, [event.target.name]: [t('すべて')]});
  }
};
/**
 * JSON配列を指定KeyでgroupByした上で１行にまとめます。
 *
 * [{uuid:1, map:01, judge:0 ..},
 *  {uuid:1, map:02, judge:1 ..},
 *  {uuid:1, map:03, judge:1 ..},
 *  {uuid:2, map:01, judge:1 ..}]
 *  -> key = "uuid" col = ["map", "judge"]
 *     return = [
 *       {uuid:1, 01:0, 02:1, 03:1 ..},
 *       {uuid:2, 01:1 ..}]
 * @param list
 * @param key
 * @param col [(keyカラム名):(Valueカラム名)]
 * @returns groupBy後のJSON配列
 */
export const groupBy = (list: Array<any>, key: string, col: string[]) => {
  let keysIndex: any = {};
  return list.reduce((acc: Array<any>, cVal: any) => {
    const idx = keysIndex[cVal[key]];
    if (typeof idx === 'undefined') {
      // 新規Rec
      let cVal2 = {...cVal, [cVal[col[0]]]: cVal[col[1]]};
      delete cVal2[key];
      acc.push(cVal2);
      keysIndex[cVal[key]] = acc.length - 1;
    } else {
      // 重複Rec
      acc[idx][cVal[col[0]]] = cVal[col[1]];
    }
    return acc;
  }, []);
};

/**
 * JSON配列を指定Keyでdistinctします。
 *
 * [{uuid:1, ..}, {uuid:1, ..}, {uuid:2, ..}, {uuid:1, ..}]
 *  -> key = "uuid" return = [1,2]
 * @param list
 * @param key
 * @returns distinct後のValue配列
 */
export const distinct = (list: Array<any>, key: string) => {
  return list
    .reduce((acc: Array<any>, cVal: any) => {
      return acc.indexOf(cVal[key]) !== -1 ? acc : [...acc, cVal[key]];
    }, [])
    .sort((a, b) => a - b);
  // .sort();
};

/**
 * 指定秒数待ちます(同期)
 * @param milliseconds
 */
export const wait = (milliseconds: number) => {
  const start = new Date().getTime();
  let end = 0;
  while (end - start < milliseconds) {
    end = new Date().getTime();
  }
};
