import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  xAxisName?: string;
  inputScale?: boolean;
  grid?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarSearchMonth = (props: Props) => {
  const {t} = useTranslation();
  const [myGrid, setMyGrid] = useState({
    top: 65,
    left: 60,
    right: 5,
    bottom: 80
  });

  let x_list: any =
    props.source !== undefined ? props.source.map((item: any) => item['cycle_time']) : [];
  let y_value: any =
    props.source !== undefined ? props.source.map((item: any) => item['カウント数']) : [];

  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  useEffect(() => {
    if (!props.grid) return;

    let newGrid: any = myGrid;
    Object.entries(props.grid).forEach(([key, value]: [string, number]) => {
      if (value) newGrid[key] = value;
    });
    setMyGrid(newGrid);
  }, [props.grid]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: {
            type: 'category',
            data: x_list,
            axisLabel: {
              rotate: '45',
              // interval: 0,
              // fontSize: 10,
              width: myGrid.bottom,
              overflow: 'truncate'
            }
          },
          yAxis: {
            type: 'value',
            name: props.xAxisName,
            // interval: 1,
            minInterval: 1,
            nameTextStyle: {
              align: 'left'
            },
            axisLabel: {
              width: myGrid.left,
              overflow: 'truncate'
            },
            min: scaleValue.y1AxisMin,
            max: scaleValue.y1AxisMax,
            interval: scaleValue.y1AxisInterval
          },
          series: [
            {
              data: y_value,
              type: 'bar'
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              return (
                prm.name +
                t('秒') +
                '<br>' +
                prm.value +
                t('件') +
                '(' +
                (
                  (prm.value / y_value.reduce((sum: number, item: any) => sum + item, 0)) *
                  100
                ).toFixed(1) +
                '%)'
              );
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: myGrid
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.xAxisName} // 元々のPropsの変数名がおかしい、中身はy軸のラベル
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBarSearchMonth;
