import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {distinct} from '_logics/LFCUtil';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  onClick?: (prm: {data: any}) => void;
  colorSelectBaseData: any;
  xlist: any;
  inputScale?: boolean;
  grid?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarSearchMonthStack = (props: Props) => {
  const {t} = useTranslation();
  const [myGrid, setMyGrid] = useState({
    top: 65,
    left: 60,
    right: 65,
    bottom: 40
  });

  let map_list: any = props.source !== undefined ? distinct(props.source, 'map') : false;

  let color: any = colorPallet;

  let map_grouping_data: any = [];
  map_list.forEach((map_name: string, index: number) => {
    let tmp: any = props.source.filter((item: any) => map_name === item.map);
    let tmp_data: any = [];
    props.xlist.forEach((date_string: string) => {
      tmp_data.push(
        tmp.filter((item: any) => item.lq_time === date_string).length > 0
          ? tmp.filter((item: any) => item.lq_time === date_string)[0].ng_count
          : '0'
      );
    });

    map_grouping_data.push({
      name: map_name,
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'series'
      },
      color:
        color[props.colorSelectBaseData.findIndex((map_item: string) => map_item === map_name)] !==
        undefined
          ? color[props.colorSelectBaseData.findIndex((map_item: string) => map_item === map_name)]
          : '#000080',
      data: tmp_data
    });
  });

  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  useEffect(() => {
    if (!props.grid) return;

    let newGrid: any = myGrid;
    Object.entries(props.grid).forEach(([key, value]: [string, number]) => {
      if (value) newGrid[key] = value;
    });
    setMyGrid(newGrid);
  }, [props.grid]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: {
            type: 'category',
            data: props.xlist,
            axisLabel: {
              // interval: 0,
              rotate: '45',
              // fontSize: 10
              width: myGrid.bottom,
              overflow: 'truncate'
            }
          },
          yAxis: {
            type: 'value',
            name: t('不良数(個)'),
            nameTextStyle: {
              align: 'left'
            },
            axisLabel: {
              width: myGrid.left,
              overflow: 'truncate'
            },
            min: scaleValue.y1AxisMin,
            max: scaleValue.y1AxisMax,
            interval: scaleValue.y1AxisInterval
          },
          series: map_grouping_data,
          tooltip: {
            trigger: 'item',
            formatter: (params: any) => {
              return (
                params.seriesName +
                '<br>' +
                params.name +
                '<br>' +
                t('不良数') +
                ':' +
                params.value +
                t('個')
              );
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: myGrid
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              props.onClick(params);
            }
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={`${t('不良数(個)')}`}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBarSearchMonthStack;
