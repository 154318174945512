import {Box, Typography} from '@mui/material';
import {DisplayData} from '../ResultsDisplay';
import {calcPosition, convertTitleFromMap} from 'utils/development';

interface DisplayImageProps {
  data: DisplayData[];
  setOpen: (uuid: string, map: string) => Promise<void>;
  openDataScreenShot: string;
  index: number;
  isDisplayName: boolean;
}

export const DisplayImage = ({
  data,
  setOpen,
  openDataScreenShot,
  index,
  isDisplayName
}: DisplayImageProps) => {
  interface ContentProps {
    data: DisplayData | undefined;
    title: string;
    top: string;
    right: string;
    isDisplayName: boolean;
  }
  const Content = ({data, title, top, right, isDisplayName}: ContentProps) => {
    if (!data || right === '0' || top === '0') return <></>;
    return (
      <Box
        onClick={() => setOpen(data.uuid, data.map)}
        sx={{
          width: '25px',
          height: '25px',
          border: data?.map === openDataScreenShot ? '7px solid yellow' : '1px solid white',
          position: 'absolute',
          top: `${top}%`,
          right: `${right}%`,
          cursor: 'pointer',
          backgroundColor: data?.judge !== 1 ? '#04b454' : '#fc0404'
        }}
      >
        <Typography
          sx={{
            position: 'absolute',
            top: '-30px',
            width: '100px',
            left: '-20px',
            fontSize: '18px'
          }}
        >
          {isDisplayName ? title : ''}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {data?.map(d => {
        return (
          <Content
            title={convertTitleFromMap(d.map)}
            data={d}
            right={calcPosition(d.work, d.map, index)[0]}
            top={calcPosition(d.work, d.map, index)[1]}
            isDisplayName={isDisplayName}
          />
        );
      })}
    </>
  );
};
